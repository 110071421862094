import { Box, createStyles, useMantineTheme } from '@mantine/core';
import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';
import ContentLoader from 'react-content-loader';

// Styles for the InfoTable container
const useInfoTableStyles = createStyles((theme) => ({
  infoTable: {
    marginBottom: 0,
    color: `${theme.black} !important`,
    border: `1px solid ${theme.colors.gray[3]}`,
    display: 'grid',
    height: 'fit-content',

    '.info-table-row': {
      display: 'grid',
      gridTemplateColumns: '30% 1fr',

      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
      },

      '.info-table-row-label': {
        padding: '10px 20px',
        backgroundColor: theme.colors.gray[1],
        borderRight: `1px solid ${theme.colors.gray[3]}`,
        alignItems: 'center',
        display: 'grid',
      },

      '.info-table-row-value': {
        padding: '10px 20px',
        color: theme.colors.gray[6],
        alignItems: 'center',
        display: 'grid',
      },

      '&.header-row': {
        width: '100%',
        gridTemplateColumns: '1fr 0',

        '.info-table-row-label': {
          justifyContent: 'center',
          width: '100%',
          fontWeight: 'bold',
          borderRight: 'none',
        },
      },

      '&.main-row': {
        '.info-table-row-label, .info-table-row-value': {
          backgroundColor: theme.colors.gray[2],
          color: theme.black,
          fontWeight: 'normal',
        },
      },
    },
  },
}));

type InfoTableRowProps = {
  label: ReactNode;
  value: ReactNode;
  className?: string;
  isHeader?: boolean;
  isLoading?: boolean;
};

export const InfoTableRow: FC<InfoTableRowProps> = ({
  label,
  value,
  className,
  isHeader,
  isLoading,
}) => {
  const theme = useMantineTheme();

  return (
    <Box
      className={classnames('info-table-row', className, {
        'header-row': isHeader,
      })}
    >
      <Box className="info-table-row-label">{label}</Box>
      {!isHeader && (
        <Box
          className="info-table-row-value"
          data-testid={`info-table-row-value-${label}`}
        >
          {isLoading ? (
            <ContentLoader
              speed={2}
              width="100%"
              height={20}
              backgroundColor={theme.colors.gray[2]}
              foregroundColor={theme.colors.gray[1]}
            >
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
            </ContentLoader>
          ) : (
            value
          )}
        </Box>
      )}
    </Box>
  );
};

const useInfoTableHeaderStyles = createStyles((theme) => ({
  infoTableHeader: {
    padding: '10px 10px',
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: theme.colors.gray[1],
  },
}));

export const InfoTableHeader: FC<{ children: ReactNode }> = ({
  children,
  ...props
}) => {
  const { classes } = useInfoTableHeaderStyles();
  return (
    <Box className={classes.infoTableHeader} {...props}>
      {children}
    </Box>
  );
};

type InfoTableProps = {
  className?: string;
  children?: ReactNode;
};

export const InfoTable: FC<InfoTableProps> = ({
  className,
  children,
  ...props
}) => {
  const { classes } = useInfoTableStyles();
  return (
    <Box className={classnames(classes.infoTable, className)} {...props}>
      {children}
    </Box>
  );
};

export default {
  Table: InfoTable,
  Row: InfoTableRow,
  Header: InfoTableHeader,
};
